<template>
  <el-dialog
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="采购明细"
    :visible.sync="show"
    width="1100px"
  >
    <div v-if="!turnover" class="productDetail-header">
      <div class="item">
        <span>采购单号：</span>
        <span>{{ currentProductDetail.purchaseNo }}</span>
      </div>
      <div class="item">
        <span>采购日期：</span>
        <span>{{ currentProductDetail.orderTimeStr }}</span>
      </div>
      <div class="item">
        <span>制单人：</span>
        <span>{{
          currentProductDetail.operatorName || currentProductDetail.operator
        }}</span>
      </div>
      <div class="item">
        <span>总数量：</span>
        <span>{{ currentProductDetail.goodsNum }}</span>
      </div>
      <div class="item">
        <span>采购金额(元)：</span>
        <span>{{ currentProductDetail.payPrice }}</span>
      </div>
      <div class="item">
        <span>采购状态：</span>
        <span>{{ currentProductDetail.statusStr }}</span>
      </div>
      <div class="item">
        <span>付款状态：</span>
        <span>{{ currentProductDetail.payStatusStr }}</span>
      </div>
      <div class="item">
        <span>付款日期：</span>
        <span>{{ currentProductDetail.payTimeStr }}</span>
      </div>
      <div class="item">
        <span>到货日期：</span>
        <span>{{ currentProductDetail.arrivalTime }}</span>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="productDetailData"
      max-height="300"
      style="width: 100%"
      top="10vh"
    >
      <el-table-column show-overflow-tooltip prop="brandName" label="品牌">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsName" label="商品名称">
      </el-table-column>
      <el-table-column show-overflow-tooltip prop="barNo" label="条形码">
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      >
      </el-table-column>
      <el-table-column show-overflow-tooltip label="规格">
        <template slot-scope="{ row }">
          <span>{{ row.specification || row.purchaseSpecification }}</span>
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="inPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="payPrice"
        label="金额小计(元)"
      >
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  props: ['currentProductDetail', 'isChild', 'turnover'],
  data() {
    return {
      loading: false,
      productDetailData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const r = this.isChild
          ? await this.$api.productPurchase.getChildOrderDetail({
              purchaseNo: this.currentProductDetail.id
            })
          : await this.$api.productPurchase.getOrderDetail({
              purchaseNo: this.turnover
                ? this.currentProductDetail.purchaseId
                : this.currentProductDetail.id
            })

        this.productDetailData = (r.detailList || []).map(item => {
          return item
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.productDetail-header {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  line-height: 26px;
}

.productDetail-header .item {
  display: flex;
}

.productDetail-header .item span:nth-child(1) {
  width: 100px;
  margin-right: 10px;
  text-align: right;
}

.productDetail-header .item span:nth-child(2) {
  width: 150px;
}
</style>
