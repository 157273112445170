<template>
  <page style="padding-top: 0">
    <el-button class="backBtn" type="primary" size="mini" @click="backHandler"
      >返回</el-button
    >

    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick(activeName)"
    >
      <el-tab-pane label="采购流水" name="first">
        <div class="formWrap">
          <el-form inline :model="params" ref="filtersForm">
            <!-- <el-form-item label="供应商" prop="supplierName">
                <el-input placeholder="供应商" v-model="params.supplierName" />
              </el-form-item> -->

            <el-form-item label="供应商">
              <el-select v-model="params.supplierId">
                <el-option
                  v-for="item in supplierList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="交易方式" prop="tradeWay">
              <el-select v-model="params.tradeWay" clearable>
                <el-option
                  v-for="item in tradeWayList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="交易类型" prop="tradeType">
              <el-select v-model="params.tradeType" clearable>
                <el-option
                  v-for="item in tradeTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="交易时间" prop="date">
              <el-date-picker
                v-model="params.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>

            <el-button type="primary" plain @click="getData(true)">
              查询
            </el-button>
            <!-- <el-button plain @click="parentClear">重置</el-button> -->
          </el-form>
        </div>

        <div class="sumWrap">
          <div class="item">
            <span class="label">累计消费：￥</span>
            <span class="value">{{ $utils.formatNum(totalConsume) }}</span>
          </div>
          <div class="item">
            <span class="label">退款：￥</span>
            <span class="value">{{ $utils.formatNum(totalRefund) }}</span>
          </div>
        </div>

        <div class="table-wrap">
          <el-table :data="tableData" v-loading="loading">
            <el-table-column show-overflow-tooltip label="交易方式">
              <template slot-scope="{ row }">
                <span>{{ tradeWayObj[+row.tradeWay] }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="交易类型">
              <template slot-scope="{ row }">
                <span>{{ row.tradeType == 1 ? '消费' : '退款' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="tradePrice"
              label="交易金额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="supplierName"
              label="供应商名称"
            />
            <el-table-column show-overflow-tooltip prop="remark" label="备注" />
            <el-table-column
              show-overflow-tooltip
              prop="tradeTime"
              label="交易时间"
            />
            <el-table-column label="操作">
              <template slot-scope="{ row }">
                <el-button @click="productDetail(row)" type="text" size="mini"
                  >查看关联订单</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="pagination-wrap">
          <el-pagination
            :total="totalCount"
            :page-size="params.limit"
            :current-page="params.page"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="sizeChange"
            @current-change="currentChange"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane label="充值\还款记录" name="second">
        <div class="formWrap">
          <el-form inline :model="params" ref="filtersForm">
            <el-form-item label="供应商">
              <el-select v-model="params.supplierId">
                <el-option
                  v-for="item in supplierList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="交易类型" prop="type">
              <el-select v-model="params.type" clearable>
                <el-option
                  v-for="item in typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="操作时间" prop="date">
              <el-date-picker
                v-model="params.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>

            <el-button type="primary" plain @click="getData(true)">
              查询
            </el-button>
            <!-- <el-button plain @click="parentClear">重置</el-button> -->
          </el-form>
        </div>

        <div class="sumWrap">
          <div class="item">
            <span class="label">现金余额：￥</span>
            <span class="value">{{ $utils.formatNum(currentAmount) }}</span>
          </div>
          <div class="item">
            <span class="label">赊销额度：￥</span>
            <span class="value">{{ $utils.formatNum(creditAmount) }}</span>
          </div>
        </div>

        <div class="table-wrap">
          <el-table :data="tableData" v-loading="loading">
            <el-table-column show-overflow-tooltip label="操作类型">
              <template slot-scope="{ row }">
                <span>{{ typeObj[+row.type] }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="amount" label="金额">
              <template slot-scope="{ row }">
                <span
                  >￥{{
                    row.type == 0
                      ? `+${row.amount}`
                      : row.type == 1
                      ? `-${row.amount}`
                      : row.amount
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              show-overflow-tooltip
              prop="finalAmount"
              label="操作后金额"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operatorName"
              label="操作员"
            />
            <el-table-column
              show-overflow-tooltip
              prop="approverName"
              label="审核员"
            />
            <el-table-column
              show-overflow-tooltip
              prop="operationTime"
              label="操作时间"
            />
          </el-table>
        </div>

        <div class="pagination-wrap">
          <el-pagination
            :total="totalCount"
            :page-size="params.limit"
            :current-page="params.page"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="sizeChange"
            @current-change="currentChange"
          />
        </div>
      </el-tab-pane>
    </el-tabs>

    <ProductDetailModal
      v-if="productDetailVisible"
      :visible.sync="productDetailVisible"
      :turnover="true"
      :currentProductDetail="currentProductDetail"
    />
  </page>
</template>

<script>
import ProductDetailModal from '../components/ProductDetailModal'

export default {
  data() {
    return {
      productDetailVisible: false,
      currentProductDetail: {},
      supplierList: [],
      activeName: 'first',
      totalConsume: 0,
      totalRefund: 0,
      currentAmount: 0,
      creditAmount: 0,
      params: {
        date: [],
        limit: 20,
        page: 1
      },
      tableData: [],
      typeObj: {
        0: '充值',
        1: '扣款',
        2: '还款',
        3: '修改额度',
        4: '临时额度'
      },
      typeList: [
        {
          label: '充值',
          value: 0
        },
        {
          label: '扣款',
          value: 1
        },
        {
          label: '还款',
          value: 2
        },
        {
          label: '修改额度',
          value: 3
        },
        {
          label: '临时额度',
          value: 4
        }
      ],
      tradeWayObj: {
        0: '扫码支付',
        1: '工行卡支付',
        2: '线下支付',
        3: '余额支付',
        4: '赊账'
      },
      tradeWayList: [
        {
          label: '扫码支付',
          value: 0
        },
        {
          label: '工行卡支付',
          value: 1
        },
        {
          label: '线下支付',
          value: 2
        },
        {
          label: '余额支付',
          value: 3
        },
        {
          label: '赊账',
          value: 4
        }
      ],
      tradeTypeList: [
        {
          label: '消费',
          value: 1
        },
        {
          label: '退款',
          value: 2
        }
      ],
      totalCount: 0,
      loading: false
    }
  },
  created() {
    this.getStoresSuppliers()
    this.activeName = this.flowQueryType
  },
  watch: {
    flowQueryType: {
      handler(val) {
        console.log(val)
        this.activeName = val
        this.getData()
      }
    }
  },
  computed: {
    flowQueryType() {
      return this.$store.state.common.flowQueryType
    }
  },
  components: {
    ProductDetailModal
  },
  methods: {
    backHandler() {
      this.$router.push({
        name: 'productPurchase'
      })
    },
    async getStoresSuppliers() {
      try {
        const res = await this.$api.common.getStoresSuppliers()

        const params = { ...this.params }

        this.supplierList = (res.suppliers || []).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })

        if (res.suppliers.length) {
          params.supplierId = res.suppliers[0].id

          this.params = params
        }
      } catch (err) {
      } finally {
        this.getData()
      }
    },
    parentClear() {
      const filtersForm = this.$refs.filtersForm
      filtersForm && filtersForm.resetFields()
      this.getData(true)
    },

    productDetail(row) {
      this.productDetailVisible = true
      this.currentProductDetail = row
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        if (params.date && params.date.length) {
          params.startTime = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endTime = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.startTime = undefined
          params.endTime = undefined
        }

        const { date, ...sendData } = params

        const res =
          this.activeName == 'first'
            ? await this.$api.productPurchase.getFlowRecord(sendData)
            : await this.$api.productPurchase.getOtherRecord(sendData)

        if (res.page && res.page.list) {
          this.tableData = res.page.list.map(item => {
            return item
          })
        }

        this.totalConsume = res.consumptionTotalAmount || 0
        this.totalRefund = res.refundTotalAmount || 0

        this.currentAmount = res.currentAmount || 0
        this.creditAmount = res.creditAmount || 0

        this.totalCount = res.page.totalCount
      } catch (err) {
      } finally {
        this.loading = false
      }
    },
    handleClick(tab) {
      this.params.date = []
      this.params.limit = 20
      this.params.date = 1

      this.$store.dispatch(`common/SET_TYPE`, tab)
    },
    sizeChange(size) {
      this.params.limit = size
      this.getData()
    },
    currentChange(page) {
      this.params.page = page
      this.getData()
    }
  }
}
</script>

<style scoped lang="scss">
.page-com {
  position: relative;
}

::v-deep .el-tabs,
::v-deep .el-tabs__content,
::v-deep .el-tab-pane,
::v-deep .el-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

::v-deep .el-table__body-wrapper {
  flex: 1;
}

.tipsHeader {
  font-weight: bold;
  margin: 10px 0;
}

.sumWrap {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 10px;

  .item {
    margin-right: 50px;
  }
}

.pagination-wrap {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.backBtn {
  position: absolute;
  top: 0;
  right: 20px;
  z-index: 10;
}
</style>
